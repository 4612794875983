import React, {Fragment, useContext, useState} from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import classes from "./MainCard.module.css";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import TextBlock from "./TextBlock";
import InfoTextFields from "./InfoTextFields";
import NextButton from "./NextButton";
import HeroImage from "./HeroImage";
import InputComponent from "./InputComponent";
import LabelComponent from "./LabelComponent";
import ChekSexComponent from "./CheckSexComponent";
import SelectedSexContext from "../store/selectedSex-context";
import ukLocale from 'date-fns/locale/uk';
registerLocale('uk', ukLocale);

const MainCard = (props) => {
    const now = new Date();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [tel, setTel] = useState("");
    const [birthdate, setBirthdate] = useState(null);
    const selectedSexCtx = useContext(SelectedSexContext);
    const enteredInputSex = selectedSexCtx.sexValue;

    const [activeComponent, setActiveComponent] = useState("FORM");
    const [serverResponseData, setServerResponseData] = useState("");

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const recaptchaRef = React.createRef();

    const handleInputChangeName = (event) => {
        setName(event.target.value);
    };

    const handleInputChangeSurname = (event) => {
        setSurname(event.target.value);
    };

    const handleInputChangeTel = (event) => {
        setTel(event.target.value);
    };

    const handleInputChangeBirthdate = (date) => {
        setBirthdate(date);
    };

    const handleCaptchaVerify = (response) => {
        setCaptchaVerified(true);
    };

    const confirmHandler = (event) => {
        event.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            alert('Пройдіть каптчу!');
            return
        }
        if (name.trim().length === 0 || surname.trim().length === 0 || tel.trim().length === 0) {
            alert('Please, enter the data into the form for submission!');
            return
        }
        fetch(
            "/register",
            {
                method: "POST",
                body: JSON.stringify({
                    id: Math.random().toString(),
                    name: name,
                    surname: surname,
                    tel: tel,
                    sex: enteredInputSex,
                    birthdate: birthdate,
                    location: window.location.pathname,
                    recaptcha: recaptchaValue
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with error status ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                setServerResponseData(data.content);
                setActiveComponent('SERVER_MESSAGE');
            })
            .catch((err) => {
                console.error("Error during fetch:", err);
                alert('У нас невеличкі проблеми. Спробуйте пізніше');
            })
    };

    return (
        <Fragment>
            <form className={classes.mainCard}>
                <HeroImage moduleClasses="image"/>
                {(activeComponent === 'SERVER_MESSAGE' &&
                        <TextBlock moduleClasses="anketa">{serverResponseData}</TextBlock>) ||
                    <Fragment>
                        <TextBlock moduleClasses="bonusCard">Bonus card 3%</TextBlock>
                        <TextBlock moduleClasses="anketa">
                            Заповніть та отримайте +10 грн бонусів
                        </TextBlock>
                        <InfoTextFields moduleClasses="infoTextFields">
                            <TextBlock moduleClasses="textFieldName">
                                {/*<LabelComponent htmlFor="name" moduleClasses="label">*/}
                                {/*    <TextBlock moduleClasses="labelName">Ім'я</TextBlock>*/}
                                {/*    <TextBlock moduleClasses="labelStar">*</TextBlock>*/}
                                {/*</LabelComponent>*/}
                                <InputComponent
                                    type="text"
                                    moduleClasses="inputName"
                                    onChange={handleInputChangeName}
                                    id="name"
                                    name="name"
                                    value={name}
                                    placeholder="Ваше ім'я"
                                />
                            </TextBlock>
                            <TextBlock moduleClasses="textFieldSurname">
                                {/*<LabelComponent htmlFor="surname" moduleClasses="label">*/}
                                {/*    <TextBlock moduleClasses="labelName">Прізвище</TextBlock>*/}
                                {/*    <TextBlock moduleClasses="labelStar">*</TextBlock>*/}
                                {/*</LabelComponent>*/}
                                <InputComponent
                                    type="text"
                                    moduleClasses="inputSurname"
                                    onChange={handleInputChangeSurname}
                                    id="surname"
                                    name="surname"
                                    value={surname}
                                    placeholder="Прізвище"
                                />
                            </TextBlock>
                            <TextBlock moduleClasses="textFieldTel">
                                {/*<LabelComponent htmlFor="tel" moduleClasses="label">*/}
                                {/*    <TextBlock moduleClasses="labelName">Телефон</TextBlock>*/}
                                {/*    <TextBlock moduleClasses="labelStar">*</TextBlock>*/}
                                {/*</LabelComponent>*/}
                                <InputMask
                                    value={tel}
                                    onChange={handleInputChangeTel}
                                    mask="+380 999999999"
                                >
                                    {() => (
                                        <div>
                                            <InputComponent
                                                type="tel"
                                                moduleClasses="inputTel"
                                                id="tel"
                                                name="tel"
                                                placeholder="+38 0931231231"
                                            />
                                        </div>
                                    )}
                                </InputMask>
                            </TextBlock>
                            <TextBlock moduleClasses="checkFieldSex">
                                {/*<LabelComponent htmlFor="sex" moduleClasses="label">*/}
                                {/*    <TextBlock moduleClasses="labelName">Стать</TextBlock>*/}
                                {/*</LabelComponent>*/}
                                <ChekSexComponent/>
                            </TextBlock>
                            <TextBlock moduleClasses="dateFieldBirthdate">
                                {/*<LabelComponent htmlFor="birthdate" moduleClasses="label">*/}
                                {/*    <TextBlock moduleClasses="labelName">Дата народження</TextBlock>*/}
                                {/*</LabelComponent>*/}
                                <DatePicker
                                    id="birthdate"
                                    className={classes.inputBirthdate}
                                    selected={birthdate}
                                    onChange={handleInputChangeBirthdate}
                                    dateFormat="dd.MM.yyyy"
                                    placeholderText="Дата Народження"
                                    locale="uk"
                                    maxDate={now}
                                    openToDate={now.setFullYear(now.getFullYear() - 8)}
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                />
                            </TextBlock>
                        </InfoTextFields>
                        <TextBlock moduleClasses="discountText">
                            Знижка буде поступово збільшуватися до 20%, а у день вашого
                            Народження надішлемо вам 100 грн бонусів.
                        </TextBlock>
                        <ReCAPTCHA
                            sitekey="6LdMk6opAAAAAG9LPMNM819hREIRpL_NMuFgHJq8"
                            onChange={handleCaptchaVerify}
                            ref={recaptchaRef}
                            lang="ua"
                        />
                        <NextButton
                            moduleClasses="buttonNext"
                            moduleClassesDiv="buttonDiv"
                            onClick={confirmHandler}
                            disabled={!captchaVerified}
                        >
                            Далі
                        </NextButton>
                    </Fragment>
                }
            </form>
        </Fragment>
    );
};

export default MainCard;
