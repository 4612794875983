import React from "react";
import classes from "./TextBlock.module.css";

const TextBlock = (props) => {
  const { moduleClasses } = props;
  return (
    <div className={classes[moduleClasses]} id={props.id}>
      {props.children}
    </div>
  );
};

export default TextBlock;
