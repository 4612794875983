import React, { useContext, useState } from "react";
import classes from "./ChekSexComponent.module.css";
import TextBlock from "./TextBlock";
import SelectedSexContext from "../store/selectedSex-context";

const ChekSexComponent = (props) => {
  const [selectedGender, setSelectedGender] = useState("");

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
  };

  const selectedSexCtx = useContext(SelectedSexContext);
  selectedSexCtx.sexValue = selectedGender;

  return (
    <div
      className={`${classes.checkSex} ${
        selectedGender ? `${classes.checkSexActive}` : ""
      }`}
    >
      <div
        id="male"
        className={`${classes.checkMan} ${
          selectedGender ? "" : `${classes.checkDefault}`
        } ${selectedGender === "male" ? `${classes.activeMan}` : ""} ${
          selectedGender === "female" ? `${classes.inactiveMan}` : ""
        }
            `}
        onClick={() => handleGenderChange("male")}
      >
        <TextBlock
          moduleClasses={`${
            selectedGender ? `checkManText activeManText` : "checkManText"
          }`}
        >
          Чоловік
        </TextBlock>
      </div>
      <div
        id="female"
        className={`${classes.checkWoman} ${
          selectedGender ? "" : `${classes.checkDefault}`
        } ${selectedGender === "female" ? `${classes.activeWoman}` : ""} ${
          selectedGender === "male" ? `${classes.inactiveWoman}` : ""
        }
            `}
        onClick={() => handleGenderChange("female")}
      >
        <TextBlock
          moduleClasses={`${
            selectedGender ? `checkWomanText activeWomanText` : "checkWomanText"
          }`}
        >
          Жінка
        </TextBlock>
      </div>
    </div>
  );
};

export default ChekSexComponent;
