import React from "react";
import classes from "./NextButton.module.css";

const NextButton = (props) => {
  const { moduleClasses, moduleClassesDiv, disabled } = props;
  return (
    <div className={classes[moduleClassesDiv]}>
      <button type="submit" disabled={disabled} className={classes[moduleClasses]} onClick={props.onClick}>
        {props.children}
      </button>
    </div>
  );
};

export default NextButton;
