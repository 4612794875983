import React from "react";

const SelectedSexContext = React.createContext({
  id: "",
  sexValue: "",
});

export const SelectedSexContextProvider = (props) => {
  const contextValue = {
    id: props.id,
    sexValue: props.sexValue,
  };

  return (
    <SelectedSexContext.Provider value={contextValue}>
      {props.children}
    </SelectedSexContext.Provider>
  );
};

export default SelectedSexContext;
