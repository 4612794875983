import React from "react";
import classes from "./InputComponent.module.css";

const InputComponent = (props) => {
  const { moduleClasses } = props;

  return (
    <input
      className={classes[moduleClasses]}
      type={props.type}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  );
};

export default InputComponent;
