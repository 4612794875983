import React from "react";
import classes from './HeroImage.module.css'

const HeroImage =(props)=>{
  const { moduleClasses } = props;
  return (
    <div className={classes[moduleClasses]}></div>
  )
}

export default HeroImage;